import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { StaticImage } from "gatsby-plugin-image"
import "../css/about.css";

export default function About() {
    return (
        <>
            <Seo title="About" />
            <BrandHeading title="about us" />
            <section>
                <div className="font-gotu text-justify text-[0.78rem] md:text-[0.85rem] tracking-wider md:tracking-[0.06em] text-pas-gray max-w-3xl mx-auto">

                    <div id="cfaboutus" className="flex items-center">
                        <div className="banner-image z-30">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel2.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="banner-image z-20">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel4.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="banner-image z-10">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel3.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="z-0 opacity-0">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel3.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                    </div>


                    <p className="font-ovo text-left md:text-left text-lg md:text-2xl tracking-wider leading-7 md:leading-8 mb-2 md:mb-3 mt-12 md:mt-[4.5rem]">Hi! I'm Thea, and to my left is my mum, Marlene.</p>
                    <p className="font-ovo text-right md:text-right text-lg md:text-2xl tracking-wider leading-7 md:leading-8 pb-7 md:pb-[3.25rem]"><span className="italic">Together</span>, we run PASTELERÍA (pahs-teh-leh-ree-ah).</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">Before we started, Mum and I were only baking for family and friends. But as more people complimented our cakes and pastries, we knew that we were onto something special. This sparked our confidence to open our home-based bakery!</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">I never imagined myself becoming a pastry chef. I've always loved drawing and painting since I was a little girl and this led me to study Spatial Design at AUT University. After graduating, I landed a job as an interior designer but later on, I realised that it wasn’t the right career for me. I then decided to take a break from my job, and during that time, I started to explore cooking and baking at home. One day, Mum suggested that I take up a short course in baking, but because I wanted to learn more than just the basics, I went back to AUT to study a 2-year Pâtisserie course and instantly fell in love with it! So after graduating, I took the plunge, said goodbye to interior design, and started my journey as a pastry chef at SkyCity.</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">As for Mum, her love for baking started when she was just in grade school. She learned how to bake from watching my grandma and aunt make cakes during birthdays and special occasions. Although she ended up with a career in IT, it has always been her dream to have her own bakeshop. And now, we couldn't be happier seeing that dream come true!</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">At Pastelería, our approach to baking is defined by our love for fine aesthetic and what Mum once said, "If people love your cakes for the taste and not just for the look, they will always come back for more." This is why we only use high quality ingredients and make everything from scratch, so that our cakes and desserts are not only beautiful on the outside, but are also packed full of flavour! And if you have special dietary requirements, we offer gluten-friendly, dairy-free & vegan options for you to enjoy.</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">So welcome! We invite you to check out our products and we look forward to baking for you!</p>

                </div>
            </section>

        </>
    )
}